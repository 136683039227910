import { SVGProps } from "react";

export default function PaymentCardIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="21"
      viewBox="0 0 25 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5271_27587)">
        <path
          d="M22.1015 1.29175H2.46824C1.37977 1.29175 0.5 2.15963 0.5 3.22935V17.4384C0.5 18.5082 1.37977 19.376 2.46824 19.376H22.1015C23.19 19.376 24.0698 18.5082 24.0698 17.4384V3.22935C24.0698 2.15963 23.19 1.29175 22.1015 1.29175ZM2.71376 3.22935H21.856C21.991 3.22935 22.1015 3.33834 22.1015 3.47155V5.16696H2.46824V3.47155C2.46824 3.33834 2.57872 3.22935 2.71376 3.22935ZM21.856 17.4384H2.71376C2.57872 17.4384 2.46824 17.3295 2.46824 17.1962V10.3339H22.1015V17.1962C22.1015 17.3295 21.991 17.4384 21.856 17.4384ZM8.35659 13.4018V15.0164C8.35659 15.2829 8.13562 15.5008 7.86555 15.5008H4.91933C4.64926 15.5008 4.42829 15.2829 4.42829 15.0164V13.4018C4.42829 13.1354 4.64926 12.9174 4.91933 12.9174H7.86555C8.13562 12.9174 8.35659 13.1354 8.35659 13.4018ZM16.2132 13.4018V15.0164C16.2132 15.2829 15.9922 15.5008 15.7221 15.5008H10.1571C9.88698 15.5008 9.66602 15.2829 9.66602 15.0164V13.4018C9.66602 13.1354 9.88698 12.9174 10.1571 12.9174H15.7221C15.9922 12.9174 16.2132 13.1354 16.2132 13.4018Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5271_27587">
          <rect width="23.5698" height="20.6678" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
