import { SVGProps } from "react";

export default function EmailIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="64"
      viewBox="0 0 68 64"
      fill="none"
      {...props}
    >
      <path
        d="M34 0L21.5372 7.80165H10.8595V14.4132L4.14875 18.6777C2.23139 19.8347 0.942139 21.9174 0.942139 24.3306V57.3884C0.942139 59.1419 1.63871 60.8236 2.87862 62.0635C4.11853 63.3034 5.80021 64 7.55371 64H60.4463C64.1157 64 67.0578 61.0578 67.0578 57.3884V24.3306C67.0578 21.9174 65.7686 19.8347 63.8512 18.6777L57.1405 14.4132V7.80165H46.4628M17.4711 14.4132H50.5289V30.5455L34 40.8595L17.4711 30.5455M20.7768 17.719V22.6777H47.2231V17.719M10.8595 22.281V26.4132L7.55371 24.3306M57.1405 22.281L60.4463 24.3306L57.1405 26.4132M20.7768 25.9835V30.9421H47.2231V25.9835H20.7768Z"
        fill="#442873"
      />
    </svg>
  );
}
