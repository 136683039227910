import { SVGProps } from "react";

export default function KnowNowIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.16961 15.16C1.75628 15.16 1.43628 15.0467 1.20961 14.82C0.996276 14.58 0.88961 14.2533 0.88961 13.84V2.04C0.88961 1.61333 0.996276 1.29333 1.20961 1.08C1.43628 0.853332 1.75628 0.739999 2.16961 0.739999C2.58294 0.739999 2.89628 0.853332 3.10961 1.08C3.33628 1.29333 3.44961 1.61333 3.44961 2.04V7.14H3.48961L9.22961 1.38C9.44294 1.16667 9.65628 1.00667 9.86961 0.899999C10.0829 0.793332 10.3229 0.739999 10.5896 0.739999C10.9763 0.739999 11.2496 0.839999 11.4096 1.04C11.5829 1.24 11.6496 1.47333 11.6096 1.74C11.5829 2.00667 11.4563 2.25333 11.2296 2.48L5.38961 8.26L5.40961 7.1L11.5096 13.3C11.7896 13.5667 11.9363 13.8467 11.9496 14.14C11.9629 14.4333 11.8696 14.68 11.6696 14.88C11.4829 15.0667 11.2029 15.16 10.8296 15.16C10.5096 15.16 10.2496 15.0933 10.0496 14.96C9.86294 14.8267 9.64294 14.6333 9.38961 14.38L3.48961 8.48H3.44961V13.84C3.44961 14.2533 3.34294 14.58 3.12961 14.82C2.91628 15.0467 2.59628 15.16 2.16961 15.16Z"
        fill="#442873"
      />
    </svg>
  );
}
