import { SVGProps } from "react";

export default function CrossIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2227_320)">
        <path d="M18.3007 5.7107C17.9107 5.3207 17.2807 5.3207 16.8907 5.7107L12.0007 10.5907L7.1107 5.7007C6.7207 5.3107 6.0907 5.3107 5.7007 5.7007C5.3107 6.0907 5.3107 6.7207 5.7007 7.1107L10.5907 12.0007L5.7007 16.8907C5.3107 17.2807 5.3107 17.9107 5.7007 18.3007C6.0907 18.6907 6.7207 18.6907 7.1107 18.3007L12.0007 13.4107L16.8907 18.3007C17.2807 18.6907 17.9107 18.6907 18.3007 18.3007C18.6907 17.9107 18.6907 17.2807 18.3007 16.8907L13.4107 12.0007L18.3007 7.1107C18.6807 6.7307 18.6807 6.0907 18.3007 5.7107Z" />
      </g>
      <defs>
        <clipPath id="clip0_2227_320">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>
  );
}
