import { SVGProps } from "react";

export default function RefreshIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.7667 4.23342C11.2733 3.73665 10.6864 3.34246 10.0399 3.07357C9.39347 2.80468 8.70017 2.66642 8.00001 2.66675C5.05334 2.66675 2.67334 5.05342 2.67334 8.00008C2.67334 10.9467 5.05334 13.3334 8.00001 13.3334C10.4867 13.3334 12.56 11.6334 13.1533 9.33342H11.7667C11.4921 10.1131 10.9822 10.7883 10.3076 11.266C9.6329 11.7436 8.82664 12.0001 8.00001 12.0001C5.79334 12.0001 4.00001 10.2067 4.00001 8.00008C4.00001 5.79342 5.79334 4.00008 8.00001 4.00008C9.10667 4.00008 10.0933 4.46008 10.8133 5.18675L8.66667 7.33342H13.3333V2.66675L11.7667 4.23342Z"
        fill="currentColor"
      />
    </svg>
  );
}
