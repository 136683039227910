import { SVGProps } from "react";

export default function UploadAttachementsIcon(props: SVGProps<SVGSVGElement>) {
  if (props?.type === "dark") {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} fill="none" {...props}>
        <rect width={60} height={60} fill="#fff" rx={30} />
        <path
          fill="#442873"
          d="M23.115 41.571H37.85v.965a.965.965 0 0 1-.965.964H20.357a.964.964 0 0 1-.964-.964V20.357a.964.964 0 0 1 .964-.964h.83v20.25a1.929 1.929 0 0 0 1.928 1.928Zm12.902-20.25h4.07l-3.858-4.484a.964.964 0 0 0-.221-.173l.01 4.657Zm4.59 1.929v16.393a.964.964 0 0 1-.964.964H23.115a.964.964 0 0 1-.964-.964V17.464a.964.964 0 0 1 .964-.964H34.09v5.786a.965.965 0 0 0 .964.964h5.554Zm-5.1 5.101-2.894-2.893a.964.964 0 0 0-1.369 0l-2.893 2.893a.968.968 0 1 0 1.37 1.37l1.244-1.254v5.39a.964.964 0 1 0 1.928 0v-5.39l1.244 1.253a.964.964 0 0 0 1.37 0 .965.965 0 0 0 0-1.369Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} fill="none" {...props}>
        <rect width={60} height={60} fill="#442873" rx={30} />
        <path
          fill="#fff"
          d="M23.115 41.571H37.85v.965a.965.965 0 0 1-.965.964H20.357a.964.964 0 0 1-.964-.964V20.357a.964.964 0 0 1 .964-.964h.83v20.25a1.929 1.929 0 0 0 1.928 1.928Zm12.902-20.25h4.07l-3.858-4.484a.964.964 0 0 0-.221-.173l.01 4.657Zm4.59 1.929v16.393a.964.964 0 0 1-.964.964H23.115a.964.964 0 0 1-.964-.964V17.464a.964.964 0 0 1 .964-.964H34.09v5.786a.965.965 0 0 0 .964.964h5.554Zm-5.1 5.101-2.894-2.893a.964.964 0 0 0-1.369 0l-2.893 2.893a.968.968 0 1 0 1.37 1.37l1.244-1.254v5.39a.964.964 0 1 0 1.928 0v-5.39l1.244 1.253a.964.964 0 0 0 1.37 0 .965.965 0 0 0 0-1.369Z"
        />
      </svg>
    );
  }
}
