import { SVGProps } from "react";

export default function IpAttorney(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.4536 7.6153C17.4347 7.6153 19.0406 6.00937 19.0406 4.02835C19.0406 2.04734 17.4347 0.441406 15.4536 0.441406C13.4726 0.441406 11.8667 2.04734 11.8667 4.02835C11.8667 6.00937 13.4726 7.6153 15.4536 7.6153Z" fill="#533F73"/>
    <path d="M3.33845 13.3672H7.36534C7.46686 13.3672 7.56838 13.2996 7.56838 13.1642V12.2167C7.56838 12.1152 7.5007 12.0137 7.36534 12.0137H3.33845C3.23693 12.0137 3.13541 12.0813 3.13541 12.2167V13.1642C3.16925 13.2996 3.23693 13.3672 3.33845 13.3672Z" fill="#533F73"/>
    <path d="M3.33845 15.7383H7.36534C7.46686 15.7383 7.56838 15.6707 7.56838 15.5353V14.5878C7.56838 14.4863 7.5007 14.3848 7.36534 14.3848H3.33845C3.23693 14.3848 3.13541 14.4524 3.13541 14.5878V15.5353C3.16925 15.6368 3.23693 15.7383 3.33845 15.7383Z" fill="#533F73"/>
    <path d="M25.7417 12.9262L22.5269 12.7909C22.3577 12.7909 22.1885 12.7232 22.087 12.5878L19.4137 9.37311C18.9399 8.79784 18.2632 8.49329 17.5187 8.49329H13.1196C12.7473 8.49329 12.1382 8.49329 10.0402 10.2529V10.1176C10.0402 9.77918 9.76946 9.47463 9.39723 9.47463H6.92695L6.65624 9.06856C6.21633 8.39177 5.26882 8.18874 4.59203 8.66249C4.28748 8.86552 4.11828 9.17007 4.01676 9.47463H1.30961C0.971219 9.47463 0.666664 9.74534 0.666664 10.1176V17.6299C0.666664 17.9682 0.937379 18.2728 1.30961 18.2728H9.46491C9.8033 18.2728 10.1079 18.0021 10.1079 17.6299V14.2798C10.8523 13.603 11.5968 12.9262 12.2397 12.3848L12.0367 18.7127C12.0367 19.1526 12.3751 19.5587 12.8488 19.5587H18.2293C18.6692 19.5587 19.0415 19.1865 19.0415 18.7127L18.8384 13.0954L19.9551 14.449C20.5981 15.2273 21.5456 15.6672 22.5269 15.701L25.8093 15.8364C25.8432 15.8364 25.8432 15.8364 25.877 15.8364C26.6892 15.8364 27.366 15.1596 27.3321 14.3475C27.1968 13.5692 26.52 12.9601 25.7417 12.9262ZM8.82196 17.0208H1.91872V10.7605H8.78812V17.0208H8.82196Z" fill="#533F73"/>
    </svg>
  );
}





