import { SVGProps } from "react";

export default function SentIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M1.7 10.2002L10.425 6.4602C10.5152 6.42177 10.5921 6.35768 10.6462 6.2759C10.7002 6.19411 10.729 6.09823 10.729 6.0002C10.729 5.90216 10.7002 5.80628 10.6462 5.7245C10.5921 5.64271 10.5152 5.57862 10.425 5.5402L1.7 1.8002C1.62445 1.76724 1.54189 1.75362 1.45975 1.76055C1.37762 1.76748 1.29851 1.79475 1.22955 1.83989C1.16059 1.88504 1.10395 1.94664 1.06475 2.01915C1.02555 2.09165 1.00502 2.17277 1.005 2.2552L1 4.5602C1 4.8102 1.185 5.0252 1.435 5.0552L8.5 6.0002L1.435 6.9402C1.185 6.9752 1 7.1902 1 7.4402L1.005 9.7452C1.005 10.1002 1.37 10.3452 1.7 10.2002Z"
        fill="currentColor"
      />
    </svg>
  );
}
