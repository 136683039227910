import { SVGProps } from "react";

export default function FundingIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3617_10973)">
        <path
          d="M16.4993 2.66669C9.13935 2.66669 3.16602 8.64002 3.16602 16C3.16602 23.36 9.13935 29.3334 16.4993 29.3334C23.8594 29.3334 29.8327 23.36 29.8327 16C29.8327 8.64002 23.8594 2.66669 16.4993 2.66669ZM17.6727 23.68V25.3334H15.3393V23.6134C14.3527 23.3734 12.1527 22.5867 11.3127 19.6667L13.5127 18.7734C13.5927 19.0667 14.286 21.56 16.7127 21.56C17.9527 21.56 19.3527 20.92 19.3527 19.4134C19.3527 18.1334 18.4193 17.4667 16.3127 16.7067C14.846 16.1867 11.846 15.3334 11.846 12.2934C11.846 12.16 11.8593 9.09335 15.3393 8.34669V6.66669H17.6727V8.32002C20.126 8.74669 21.0194 10.7067 21.2193 11.2934L19.1127 12.1867C18.966 11.72 18.326 10.4 16.5793 10.4C15.646 10.4 14.166 10.8934 14.166 12.2534C14.166 13.52 15.3127 14 17.686 14.7867C20.886 15.8934 21.6993 17.52 21.6993 19.3867C21.6993 22.8934 18.366 23.56 17.6727 23.68Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3617_10973">
          <rect width="32" height="32" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
