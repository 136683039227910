import { SVGProps } from "react";

export default function TeamPlusIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5221_91279)">
        <path
          d="M7.19961 12C9.18783 12 10.7996 10.3883 10.7996 8.40005C10.7996 6.41182 9.18783 4.80005 7.19961 4.80005C5.21138 4.80005 3.59961 6.41182 3.59961 8.40005C3.59961 10.3883 5.21138 12 7.19961 12Z"
          fill="#7F4BD8"
        />
        <path
          d="M13.236 14.8441C11.472 13.8001 9.408 13.2001 7.2 13.2001C4.992 13.2001 2.928 13.8001 1.164 14.8441C0.432 15.2641 0 16.0681 0 16.9081V19.2001H14.4V16.9081C14.4 16.0681 13.968 15.2641 13.236 14.8441Z"
          fill="#7F4BD8"
        />
        <path
          d="M18.0361 14.8441C16.9321 14.1961 15.7081 13.7401 14.4121 13.4761C15.5161 14.2441 16.1881 15.5281 16.1881 16.9081V19.2001H19.2001V16.9081C19.2001 16.0681 18.7681 15.2641 18.0361 14.8441Z"
          fill="#7F4BD8"
        />
        <path
          d="M11.9999 12C13.9919 12 15.5999 10.392 15.5999 8.40005C15.5999 6.40805 13.9919 4.80005 11.9999 4.80005C11.7479 4.80005 11.4959 4.82405 11.2559 4.87205C12.0839 5.82005 12.5999 7.04405 12.5999 8.40005C12.5999 9.75605 12.0839 10.968 11.2559 11.916C11.4959 11.976 11.7479 12 11.9999 12Z"
          fill="#7F4BD8"
        />
        <path
          d="M23.4004 9.30007H21.3004V7.20007H19.5004V9.30007H17.4004V11.1001H19.5004V13.2001H21.3004V11.1001H23.4004V9.30007Z"
          fill="#7F4BD8"
        />
      </g>
      <defs>
        <clipPath id="clip0_5221_91279">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
