import { SVGProps } from "react";

export default function TableSort(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_600_18153)">
        <path
          d="M10.7948 13.79C11.0848 14.07 11.5448 14.07 11.8348 13.79L14.5848 11.14C14.6591 11.0726 14.7192 10.9909 14.7614 10.8998C14.8036 10.8087 14.8271 10.7101 14.8305 10.6098C14.8339 10.5095 14.8171 10.4095 14.7811 10.3158C14.7452 10.2221 14.6908 10.1366 14.6211 10.0643C14.5515 9.99196 14.4681 9.93437 14.3758 9.8949C14.2835 9.85543 14.1842 9.83489 14.0839 9.8345C13.9835 9.8341 13.8841 9.85385 13.7915 9.89259C13.6989 9.93132 13.615 9.98825 13.5448 10.06L12.0648 11.486V2.75C12.0648 2.55109 11.9858 2.36032 11.8451 2.21967C11.7045 2.07902 11.5137 2 11.3148 2C11.1159 2 10.9251 2.07902 10.7845 2.21967C10.6438 2.36032 10.5648 2.55109 10.5648 2.75V11.486L9.08479 10.06C9.0146 9.98825 8.93071 9.93132 8.83812 9.89259C8.74552 9.85385 8.64609 9.8341 8.54571 9.8345C8.44534 9.83489 8.34607 9.85543 8.25378 9.8949C8.16149 9.93437 8.07806 9.99196 8.00844 10.0643C7.93882 10.1366 7.88442 10.2221 7.84846 10.3158C7.8125 10.4095 7.79572 10.5095 7.79911 10.6098C7.8025 10.7101 7.82599 10.8087 7.86819 10.8998C7.91039 10.9909 7.97044 11.0726 8.04479 11.14L10.7948 13.79ZM5.34479 2.22C5.20416 2.07955 5.01354 2.00066 4.81479 2.00066C4.61604 2.00066 4.42541 2.07955 4.28479 2.22L1.53479 4.97C1.40231 5.11217 1.33018 5.30022 1.33361 5.49452C1.33704 5.68882 1.41575 5.87421 1.55317 6.01162C1.69058 6.14903 1.87596 6.22775 2.07027 6.23117C2.26457 6.2346 2.45261 6.16248 2.59479 6.03L4.06479 4.56V13.25C4.06479 13.4489 4.14381 13.6397 4.28446 13.7803C4.42511 13.921 4.61588 14 4.81479 14C5.0137 14 5.20447 13.921 5.34512 13.7803C5.48577 13.6397 5.56479 13.4489 5.56479 13.25V4.56L7.03479 6.03C7.17696 6.16248 7.36501 6.2346 7.55931 6.23117C7.75361 6.22775 7.939 6.14903 8.07641 6.01162C8.21382 5.87421 8.29254 5.68882 8.29596 5.49452C8.29939 5.30022 8.22727 5.11217 8.09479 4.97L5.34479 2.22Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_600_18153">
          <rect width="16" height="16" fill="white" transform="translate(0.333496)" />
        </clipPath>
      </defs>
    </svg>
  );
}
