import { SVGProps } from "react";

export default function FacebookIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.38226 0C1.95468 0 0 1.95468 0 4.38225V19.6177C0 22.0453 1.95468 24 4.38226 24H12.6398V14.6175H10.1588V11.2395H12.6398V8.35349C12.6398 6.0861 14.1057 4.00425 17.4825 4.00425C18.8497 4.00425 19.8608 4.1355 19.8608 4.1355L19.7813 7.29001C19.7813 7.29001 18.7501 7.28027 17.625 7.28027C16.4073 7.28027 16.212 7.84134 16.212 8.77277V11.2395H19.878L19.7183 14.6175H16.212V24H19.6177C22.0453 24 24 22.0453 24 19.6178V4.38227C24 1.9547 22.0453 2.4e-05 19.6177 2.4e-05H4.38223L4.38226 0Z" />
    </svg>
  );
}
