import React from "react";
import Users from "./users";

const MyUsers = () => {
  return (
    <div className="flex gap-x-[20px] w-full mx-auto">
      <Users />
    </div>
  );
};

export default MyUsers;
