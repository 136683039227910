import { SVGProps } from "react";

export default function InsertLinkIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="92"
      height="91"
      viewBox="0 0 92 91"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_8233_19101)">
        <path
          d="M30.7263 60.5285C27.5019 57.3041 27.5019 52.0621 30.7263 48.8377L38.2688 41.2952L34.6861 37.7125L27.1437 45.255C21.9394 50.4593 21.9394 58.9069 27.1437 64.1112C32.348 69.3155 40.7955 69.3155 45.9998 64.1112L53.5423 56.5687L49.9596 52.9861L42.4172 60.5285C39.1928 63.7529 33.9507 63.7529 30.7263 60.5285ZM40.343 54.6831L55.4279 39.5982L51.6567 35.8269L36.5718 50.9119L40.343 54.6831ZM45.9998 26.3988L38.4574 33.9413L42.04 37.524L49.5825 29.9815C52.8069 26.7571 58.0489 26.7571 61.2734 29.9815C64.4978 33.2059 64.4978 38.4479 61.2734 41.6723L53.7309 49.2148L57.3136 52.7975L64.856 45.255C70.0603 40.0507 70.0603 31.6031 64.856 26.3988C59.6517 21.1945 51.2042 21.1945 45.9998 26.3988Z"
          fill="#442873"
        />
      </g>
      <rect
        x="0.745117"
        y="45.2549"
        width="64"
        height="64"
        rx="8"
        transform="rotate(-45 0.745117 45.2549)"
        stroke="#442873"
        strokeWidth="2"
      />
      <defs>
        <clipPath id="clip0_8233_19101">
          <rect
            x="0.745117"
            y="45.2549"
            width="64"
            height="64"
            rx="8"
            transform="rotate(-45 0.745117 45.2549)"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
