import { SVGProps } from "react";

export default function CopyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M10.0002 0.666748H2.66683C1.9335 0.666748 1.3335 1.26675 1.3335 2.00008V10.6667C1.3335 11.0334 1.6335 11.3334 2.00016 11.3334C2.36683 11.3334 2.66683 11.0334 2.66683 10.6667V2.66675C2.66683 2.30008 2.96683 2.00008 3.3335 2.00008H10.0002C10.3668 2.00008 10.6668 1.70008 10.6668 1.33341C10.6668 0.966748 10.3668 0.666748 10.0002 0.666748ZM12.6668 3.33342H5.3335C4.60016 3.33342 4.00016 3.93342 4.00016 4.66675V14.0001C4.00016 14.7334 4.60016 15.3334 5.3335 15.3334H12.6668C13.4002 15.3334 14.0002 14.7334 14.0002 14.0001V4.66675C14.0002 3.93342 13.4002 3.33342 12.6668 3.33342ZM12.0002 14.0001H6.00016C5.6335 14.0001 5.3335 13.7001 5.3335 13.3334V5.33342C5.3335 4.96675 5.6335 4.66675 6.00016 4.66675H12.0002C12.3668 4.66675 12.6668 4.96675 12.6668 5.33342V13.3334C12.6668 13.7001 12.3668 14.0001 12.0002 14.0001Z"
        fill="currentColor"
      />
    </svg>
  );
}
