import { SVGProps } from "react";

export default function SummaryIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4944_113077)">
        <path d="M15.1911 4.52444C14.8533 4.18667 14.4 4 13.9289 4H5.77778C4.8 4 4.00889 4.8 4.00889 5.77778L4 18.2222C4 19.2 4.79111 20 5.76889 20H18.2222C19.2 20 20 19.2 20 18.2222V10.0711C20 9.6 19.8133 9.14667 19.4756 8.81778L15.1911 4.52444ZM8.44444 16.4444C7.95556 16.4444 7.55556 16.0444 7.55556 15.5556C7.55556 15.0667 7.95556 14.6667 8.44444 14.6667C8.93333 14.6667 9.33333 15.0667 9.33333 15.5556C9.33333 16.0444 8.93333 16.4444 8.44444 16.4444ZM8.44444 12.8889C7.95556 12.8889 7.55556 12.4889 7.55556 12C7.55556 11.5111 7.95556 11.1111 8.44444 11.1111C8.93333 11.1111 9.33333 11.5111 9.33333 12C9.33333 12.4889 8.93333 12.8889 8.44444 12.8889ZM8.44444 9.33333C7.95556 9.33333 7.55556 8.93333 7.55556 8.44444C7.55556 7.95556 7.95556 7.55556 8.44444 7.55556C8.93333 7.55556 9.33333 7.95556 9.33333 8.44444C9.33333 8.93333 8.93333 9.33333 8.44444 9.33333ZM13.7778 9.33333V5.33333L18.6667 10.2222H14.6667C14.1778 10.2222 13.7778 9.82222 13.7778 9.33333Z" />
      </g>
      <defs>
        <clipPath id="clip0_4944_113077">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
