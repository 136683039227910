import { SVGProps } from "react";

export default function TechnologiesIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M17.8453 21C17.4663 21.0003 17.094 20.9017 16.7658 20.714C16.4376 20.5264 16.1652 20.2563 15.976 19.9311L9.13257 8.19105C8.85153 7.70071 8.7776 7.12019 8.92689 6.57608C9.07619 6.03196 9.4366 5.56837 9.92955 5.28637C10.4225 5.00438 11.008 4.92685 11.5584 5.07069C12.1089 5.21453 12.5796 5.56808 12.8679 6.05425L19.7113 17.7943C19.9004 18.119 20 18.4872 20 18.862C20 19.2368 19.9006 19.6051 19.7116 19.9298C19.5226 20.2545 19.2507 20.5242 18.9232 20.712C18.5957 20.8998 18.2241 20.9989 17.8457 20.9995L17.8453 21ZM9.92182 20.9995H4.15706C3.58497 20.9995 3.03631 20.7744 2.63179 20.3738C2.22726 19.9731 2 19.4296 2 18.863C2 18.2963 2.22726 17.7529 2.63179 17.3522C3.03631 16.9515 3.58497 16.7264 4.15706 16.7264H9.92182C10.4939 16.7264 11.0424 16.9515 11.4469 17.3521C11.8514 17.7528 12.0787 18.2961 12.0787 18.8627C12.0787 19.4293 11.8514 19.9727 11.4469 20.3734C11.0424 20.774 10.4939 20.9991 9.92182 20.9991V20.9995Z"
        stroke="#636567"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M4.12188 21C3.74921 20.9995 3.38323 20.9006 3.06067 20.7133C2.7381 20.526 2.4703 20.2569 2.28413 19.9329C2.09796 19.6089 1.99997 19.2415 2 18.8675C2.00003 18.4935 2.09807 18.1261 2.28428 17.8022L9.02336 6.08919C9.16086 5.84305 9.34569 5.62675 9.56714 5.45285C9.78859 5.27895 10.0422 5.15091 10.3134 5.07615C10.5845 5.0014 10.8678 4.98142 11.1467 5.01737C11.4256 5.05333 11.6946 5.1445 11.9381 5.28561C12.1816 5.42671 12.3948 5.61493 12.5653 5.83936C12.7357 6.06378 12.8601 6.31994 12.9311 6.59297C13.0021 6.866 13.0184 7.15047 12.9791 7.42987C12.9397 7.70928 12.8454 7.97805 12.7017 8.22061L5.96312 19.9336C5.77666 20.2582 5.50823 20.5276 5.18489 20.7148C4.86155 20.9021 4.49473 21.0004 4.12143 21H4.12188Z"
        stroke="#636567"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
