import { SVGProps } from "react";

export default function ReportsSidebarIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3556 5.61225C13.7079 5.61225 14.8042 4.51593 14.8042 3.16355C14.8042 1.81116 13.7079 0.714844 12.3556 0.714844C11.0033 0.714844 9.90698 1.81116 9.90698 3.16355C9.90698 4.51593 11.0033 5.61225 12.3556 5.61225Z"
        fill="#533F73"
      />
      <path
        d="M3.30406 21.2871C4.65639 21.2871 5.75266 20.1907 5.75266 18.8384C5.75266 17.486 4.65639 16.3896 3.30406 16.3896C1.95174 16.3896 0.855469 17.486 0.855469 18.8384C0.855469 20.1907 1.95174 21.2871 3.30406 21.2871Z"
        fill="#533F73"
      />
      <path
        d="M21.4025 21.2871C22.7548 21.2871 23.851 20.1907 23.851 18.8384C23.851 17.486 22.7548 16.3896 21.4025 16.3896C20.0501 16.3896 18.9539 17.486 18.9539 18.8384C18.9539 20.1907 20.0501 21.2871 21.4025 21.2871Z"
        fill="#533F73"
      />
      <path
        d="M12.9871 5.52635C10.6217 6.15989 5.03413 15.838 5.66861 18.203L2.66956 16.4713C5.03488 15.838 10.6225 6.15987 9.98798 3.79492L12.9871 5.52635Z"
        fill="#533F73"
      />
      <path
        d="M14.7204 3.79492C14.0864 6.16035 19.6735 15.8378 22.0386 16.4713L19.039 18.2024C19.673 15.837 14.0859 6.15989 11.7211 5.52635L14.7204 3.79492Z"
        fill="#533F73"
      />
      <path
        d="M5.03442 17.1074C6.76575 18.8388 17.94 18.8388 19.6718 17.1074V20.5708C17.9409 18.8393 6.76624 18.8393 5.03442 20.5708V17.1074Z"
        fill="#533F73"
      />
    </svg>
  );
}
