import { SVGProps } from "react";

export default function ThumbsUpIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M5.99984 13.9998H11.9998C12.5532 13.9998 13.0265 13.6665 13.2265 13.1865L15.2398 8.4865C15.2998 8.33317 15.3332 8.17317 15.3332 7.99984V6.6665C15.3332 5.93317 14.7332 5.33317 13.9998 5.33317H9.79317L10.4265 2.2865L10.4465 2.07317C10.4465 1.79984 10.3332 1.5465 10.1532 1.3665L9.4465 0.666504L5.05317 5.05984C4.81317 5.29984 4.6665 5.63317 4.6665 5.99984V12.6665C4.6665 13.3998 5.2665 13.9998 5.99984 13.9998ZM5.99984 5.99984L8.89317 3.1065L7.99984 6.6665H13.9998V7.99984L11.9998 12.6665H5.99984V5.99984ZM0.666504 5.99984H3.33317V13.9998H0.666504V5.99984Z"
        fill="#87888C"
      />
    </svg>
  );
}
