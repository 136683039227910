import { SVGProps } from "react";

export default function DetailedQAAIcon(props: SVGProps<SVGSVGElement>) {
  if (props?.type === "dark") {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} fill="none" {...props}>
        <rect width={60} height={60} fill="#fff" rx={30} />
        <path
          fill="#442873"
          d="M37.213 16.823H17.065a.562.562 0 0 0-.566.561v14.492c0 .317.254.565.566.565h1.67c.24 0 .435.195.435.435v4.548l2.3-2.3a.05.05 0 0 1 .02-.02l2.532-2.536a.441.441 0 0 1 .308-.127h12.883a.564.564 0 0 0 .562-.565V17.384a.56.56 0 0 0-.562-.56ZM22.09 25.637a1.006 1.006 0 0 1-1.004-1.005c0-.552.452-1.004 1.004-1.004.553 0 1.004.452 1.004 1.004 0 .553-.451 1.005-1.004 1.005Zm5.05 0a1.004 1.004 0 1 1 0-2.008 1.004 1.004 0 0 1 0 2.008Zm5.042 0a1.006 1.006 0 0 1-1.004-1.005c0-.552.45-1.004 1.004-1.004.552 0 1.004.452 1.004 1.004 0 .553-.452 1.005-1.005 1.005Z"
        />
        <path
          fill="#442873"
          d="M43.5 23.133v14.496a.563.563 0 0 1-.565.565h-1.67a.434.434 0 0 0-.435.435v4.547l-4.856-4.855a.441.441 0 0 0-.308-.127h-12.88a.564.564 0 0 1-.56-.565v-2.025l2.29-2.291h12.699c.789 0 1.434-.641 1.434-1.439v-9.306h4.29c.309 0 .562.257.562.565Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} fill="none" {...props}>
        <rect width={60} height={60} fill="#442873" rx={30} />
        <path
          fill="#fff"
          d="M37.213 16.823H17.065a.562.562 0 0 0-.566.561v14.492c0 .317.254.565.566.565h1.67c.24 0 .435.195.435.435v4.548l2.3-2.3a.05.05 0 0 1 .02-.02l2.532-2.536a.441.441 0 0 1 .308-.127h12.883a.564.564 0 0 0 .562-.565V17.384a.56.56 0 0 0-.562-.56ZM22.09 25.637a1.006 1.006 0 0 1-1.004-1.005c0-.552.452-1.004 1.004-1.004.553 0 1.004.452 1.004 1.004 0 .553-.451 1.005-1.004 1.005Zm5.05 0a1.004 1.004 0 1 1 0-2.008 1.004 1.004 0 0 1 0 2.008Zm5.042 0a1.006 1.006 0 0 1-1.004-1.005c0-.552.45-1.004 1.004-1.004.552 0 1.004.452 1.004 1.004 0 .553-.452 1.005-1.005 1.005Z"
        />
        <path
          fill="#fff"
          d="M43.5 23.133v14.496a.563.563 0 0 1-.565.565h-1.67a.434.434 0 0 0-.435.435v4.547l-4.856-4.855a.441.441 0 0 0-.308-.127h-12.88a.564.564 0 0 1-.56-.565v-2.025l2.29-2.291h12.699c.789 0 1.434-.641 1.434-1.439v-9.306h4.29c.309 0 .562.257.562.565Z"
        />
      </svg>
    );
  }
}
