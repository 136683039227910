import { SVGProps } from "react";

export default function ShareIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_9297_1386)">
        <path
          d="M12.0005 10.7199C11.4938 10.7199 11.0405 10.9199 10.6938 11.2333L5.94049 8.46658C5.97382 8.31325 6.00049 8.15992 6.00049 7.99992C6.00049 7.83992 5.97382 7.68658 5.94049 7.53325L10.6405 4.79325C11.0005 5.12659 11.4738 5.33325 12.0005 5.33325C13.1072 5.33325 14.0005 4.43992 14.0005 3.33325C14.0005 2.22659 13.1072 1.33325 12.0005 1.33325C10.8938 1.33325 10.0005 2.22659 10.0005 3.33325C10.0005 3.49325 10.0272 3.64658 10.0605 3.79992L5.36049 6.53992C5.00049 6.20659 4.52715 5.99992 4.00049 5.99992C2.89382 5.99992 2.00049 6.89325 2.00049 7.99992C2.00049 9.10659 2.89382 9.99992 4.00049 9.99992C4.52715 9.99992 5.00049 9.79325 5.36049 9.45992L10.1072 12.2333C10.0738 12.3733 10.0538 12.5199 10.0538 12.6666C10.0538 13.7399 10.9272 14.6133 12.0005 14.6133C13.0738 14.6133 13.9472 13.7399 13.9472 12.6666C13.9472 11.5933 13.0738 10.7199 12.0005 10.7199Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_9297_1386">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
