import { SVGProps } from "react";

export default function InfoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={props.fontSize || "24"}
      height={props.fontSize || "24"}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1607_32341)">
        <path
          d="M11.525 17.025H13.325V10.875H11.525V17.025ZM12.35 9.225C12.65 9.225 12.8958 9.12917 13.0875 8.9375C13.2792 8.74583 13.375 8.50833 13.375 8.225C13.375 7.90833 13.2792 7.65417 13.0875 7.4625C12.8958 7.27083 12.65 7.175 12.35 7.175C12.05 7.175 11.8042 7.27083 11.6125 7.4625C11.4208 7.65417 11.325 7.90833 11.325 8.225C11.325 8.50833 11.4208 8.74583 11.6125 8.9375C11.8042 9.12917 12.05 9.225 12.35 9.225ZM12.35 22.225C10.9167 22.225 9.575 21.9542 8.325 21.4125C7.075 20.8708 5.97917 20.1292 5.0375 19.1875C4.09583 18.2458 3.35417 17.15 2.8125 15.9C2.27083 14.65 2 13.3083 2 11.875C2 10.425 2.27083 9.075 2.8125 7.825C3.35417 6.575 4.09167 5.48333 5.025 4.55C5.95833 3.61667 7.05417 2.875 8.3125 2.325C9.57083 1.775 10.9167 1.5 12.35 1.5C13.8 1.5 15.1542 1.775 16.4125 2.325C17.6708 2.875 18.7625 3.6125 19.6875 4.5375C20.6125 5.4625 21.35 6.55417 21.9 7.8125C22.45 9.07083 22.725 10.425 22.725 11.875C22.725 13.3083 22.45 14.6542 21.9 15.9125C21.35 17.1708 20.6083 18.2667 19.675 19.2C18.7417 20.1333 17.65 20.8708 16.4 21.4125C15.15 21.9542 13.8 22.225 12.35 22.225ZM12.375 20.25C14.6917 20.25 16.6625 19.4333 18.2875 17.8C19.9125 16.1667 20.725 14.1833 20.725 11.85C20.725 9.53333 19.9125 7.5625 18.2875 5.9375C16.6625 4.3125 14.6833 3.5 12.35 3.5C10.0333 3.5 8.05833 4.3125 6.425 5.9375C4.79167 7.5625 3.975 9.54167 3.975 11.875C3.975 14.1917 4.79167 16.1667 6.425 17.8C8.05833 19.4333 10.0417 20.25 12.375 20.25Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1607_32341">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
