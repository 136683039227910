import { SVGProps } from "react";

export default function NewReportIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6415 0.00562983C11.6075 0.00190036 11.5732 0 11.5385 0H3.84615C1.72198 0 0 1.39911 0 3.125V16.875C0 18.6009 1.72198 20 3.84615 20H16.1538C18.278 20 20 18.6009 20 16.875V6.875C20 6.86188 19.9995 6.84875 19.9985 6.83569L11.6415 0.00562983ZM12.3077 4.375C12.3077 5.41053 13.3408 6.25 14.6154 6.25H17.3737L12.3077 2.13388V4.375ZM6.15385 11.25C6.15385 10.9048 6.49824 10.625 6.92308 10.625H9.23077V8.75C9.23077 8.40481 9.57515 8.125 10 8.125C10.4248 8.125 10.7692 8.40481 10.7692 8.75V10.625H13.0769C13.5018 10.625 13.8462 10.9048 13.8462 11.25C13.8462 11.5952 13.5018 11.875 13.0769 11.875H10.7692V13.75C10.7692 14.0952 10.4248 14.375 10 14.375C9.57515 14.375 9.23077 14.0952 9.23077 13.75V11.875H6.92308C6.49824 11.875 6.15385 11.5952 6.15385 11.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
