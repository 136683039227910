import { SVGProps } from "react";

export default function DashboardIcon(props: SVGProps<SVGSVGElement>) {
  return (
    // <svg
    //   width="24"
    //   height="24"
    //   viewBox="0 0 24 24"
    //   fill="currentColor"
    //   xmlns="http://www.w3.org/2000/svg"
    //   {...props}
    // >
    //   <path d="M3 13H11V3H3V13ZM3 21H11V15H3V21ZM13 21H21V11H13V21ZM13 3V9H21V3H13Z" />
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path
        d="M11.5003 5.4669C11.6203 5.34705 11.783 5.27973 11.9526 5.27973C12.1222 5.27973 12.2848 5.34705 12.4048 5.4669L19.8203 12.8824C19.8789 12.9452 19.9495 12.9957 20.0281 13.0307C20.1066 13.0656 20.1913 13.0844 20.2772 13.086C20.3632 13.0875 20.4485 13.0717 20.5282 13.0395C20.6079 13.0073 20.6803 12.9594 20.7411 12.8986C20.8019 12.8378 20.8498 12.7654 20.882 12.6858C20.9141 12.6061 20.9299 12.5207 20.9284 12.4348C20.9269 12.3488 20.9081 12.2641 20.8731 12.1856C20.8381 12.1071 20.7877 12.0364 20.7248 11.9778L13.3102 4.56236C13.1319 4.38407 12.9203 4.24264 12.6873 4.14615C12.4544 4.04966 12.2047 4 11.9526 4C11.7004 4 11.4508 4.04966 11.2178 4.14615C10.9849 4.24264 10.7732 4.38407 10.5949 4.56236L3.17945 11.9778C3.06292 12.0986 2.99849 12.2603 3.00003 12.4281C3.00156 12.5959 3.06895 12.7564 3.18767 12.875C3.30638 12.9936 3.46694 13.0608 3.63474 13.0622C3.80255 13.0636 3.96419 12.999 4.08484 12.8824L11.5003 5.4669Z"
        fill="currentColor"
      />

      <path
        d="M11.9531 6.82593L18.9154 13.7883C18.941 13.8139 18.9666 13.8378 18.9931 13.8617V19.1506C18.9931 20.0338 18.2763 20.7506 17.3931 20.7506H14.5131C14.3433 20.7506 14.1806 20.6832 14.0605 20.5632C13.9405 20.4431 13.8731 20.2804 13.8731 20.1106V16.2706C13.8731 16.1009 13.8057 15.9381 13.6856 15.8181C13.5656 15.698 13.4028 15.6306 13.2331 15.6306H10.6731C10.5033 15.6306 10.3406 15.698 10.2205 15.8181C10.1005 15.9381 10.0331 16.1009 10.0331 16.2706V20.1106C10.0331 20.2804 9.96566 20.4431 9.84563 20.5632C9.72561 20.6832 9.56282 20.7506 9.39309 20.7506H6.51309C6.08874 20.7506 5.68177 20.582 5.38172 20.282C5.08166 19.9819 4.91309 19.575 4.91309 19.1506V13.8617C4.93963 13.8379 4.96553 13.8134 4.99074 13.7883L11.9531 6.82422V6.82593Z"
        fill="currentColor"
      />
    </svg>
  );
}
