import { SVGProps } from "react";

export default function LightBulbIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="36"
      fill="none"
      viewBox="0 0 37 36"
      {...props}
    >
      <path
        fill="#FFA300"
        d="M18.017 5.63a1.13 1.13 0 01-1.126-1.126V1.126c0-.62.506-1.126 1.126-1.126.619 0 1.126.507 1.126 1.126v3.378a1.13 1.13 0 01-1.126 1.126zM8.457 9.583c-.62 0-.642-.17-3.187-2.714a1.121 1.121 0 010-1.588c.44-.44 1.149-.44 1.588 0l2.387 2.387c.71.71.192 1.926-.8 1.926l.012-.011zM4.504 19.143H1.126A1.13 1.13 0 010 18.017c0-.62.507-1.126 1.126-1.126h3.378c.62 0 1.126.506 1.126 1.126a1.13 1.13 0 01-1.126 1.126zM6.07 31.09c-.991 0-1.51-1.205-.8-1.926l2.387-2.387a1.121 1.121 0 111.588 1.588c-2.59 2.59-2.567 2.714-3.187 2.714l.012.01zM29.964 31.09c-.62 0-.642-.169-3.187-2.713a1.122 1.122 0 111.588-1.588l2.387 2.387c.71.71.192 1.926-.8 1.926l.012-.012zM34.907 19.143H31.53a1.13 1.13 0 01-1.126-1.126c0-.62.507-1.126 1.126-1.126h3.378c.62 0 1.127.506 1.127 1.126a1.13 1.13 0 01-1.127 1.126zM27.577 9.583c-.991 0-1.509-1.205-.8-1.926l2.388-2.387c.439-.44 1.148-.44 1.587 0 .44.44.44 1.149 0 1.588-2.59 2.59-2.567 2.714-3.186 2.714l.01.01zM19.131 7.939a10.134 10.134 0 00-9.976 5.157 1.13 1.13 0 00.439 1.532 1.13 1.13 0 001.531-.44 7.906 7.906 0 017.77-4.02c6.295.676 9.38 8.39 4.898 13.198-1.644 1.756-2.77 3.76-3.299 5.889h-1.34v-6.756h1.126a1.13 1.13 0 001.126-1.126 1.13 1.13 0 00-1.126-1.126h-4.504a1.13 1.13 0 00-1.126 1.126c0 .619.507 1.126 1.126 1.126h1.126v6.756h-1.329c-.518-2.128-1.633-4.133-3.243-5.822a7.858 7.858 0 01-2.184-5.438 1.13 1.13 0 00-1.126-1.127 1.13 1.13 0 00-1.126 1.127c0 2.612 1.002 5.1 2.803 6.992 1.498 1.566 2.467 3.435 2.827 5.507v1.002A4.503 4.503 0 0018.028 36a4.503 4.503 0 004.504-4.504V30.46c.327-1.982 1.318-3.862 2.917-5.574 5.731-6.16 1.824-16.102-6.306-16.98l-.012.033zm-1.114 25.843a2.259 2.259 0 01-2.252-2.252h4.504a2.259 2.259 0 01-2.252 2.252z"
      ></path>
    </svg>
  );
}
