import { SVGProps } from "react";

export default function MapMarkerIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 30 31" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="path-1-outside-1_1299_7706"
        maskUnits="userSpaceOnUse"
        x="0.666992"
        y="0.666626"
        width="29"
        height="30"
        fill="black"
      >
        <rect fill="currentColor" x="0.666992" y="0.666626" width="29" height="30" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2767 25.2716C6.72712 24.5203 1.66699 19.5445 1.66699 13.5182C1.66699 6.97274 7.63637 1.66663 15 1.66663C22.3636 1.66663 28.3329 6.97274 28.3329 13.5182C28.3329 19.5452 23.2717 24.5214 16.721 25.2719L14.9989 28.3334L13.2767 25.2716Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2767 25.2716C6.72712 24.5203 1.66699 19.5445 1.66699 13.5182C1.66699 6.97274 7.63637 1.66663 15 1.66663C22.3636 1.66663 28.3329 6.97274 28.3329 13.5182C28.3329 19.5452 23.2717 24.5214 16.721 25.2719L14.9989 28.3334L13.2767 25.2716Z"
        fill="currentColor"
      />
      <path
        d="M13.2767 25.2716L14.1482 24.7813L13.8979 24.3363L13.3906 24.2781L13.2767 25.2716ZM16.721 25.2719L16.6072 24.2784L16.0998 24.3365L15.8495 24.7816L16.721 25.2719ZM14.9989 28.3334L14.1273 28.8237L14.9989 30.3731L15.8705 28.8237L14.9989 28.3334ZM0.666992 13.5182C0.666992 20.1484 6.20947 25.4675 13.1627 26.2651L13.3906 24.2781C7.24477 23.5731 2.66699 18.9406 2.66699 13.5182H0.666992ZM15 0.666626C7.19806 0.666626 0.666992 6.31298 0.666992 13.5182H2.66699C2.66699 7.6325 8.07467 2.66663 15 2.66663V0.666626ZM29.3329 13.5182C29.3329 6.31298 22.8019 0.666626 15 0.666626V2.66663C21.9253 2.66663 27.3329 7.6325 27.3329 13.5182H29.3329ZM16.8348 26.2654C23.7892 25.4687 29.3329 20.1492 29.3329 13.5182H27.3329C27.3329 18.9412 22.7541 23.5742 16.6072 24.2784L16.8348 26.2654ZM15.8495 24.7816L14.1273 27.8431L15.8705 28.8237L17.5926 25.7621L15.8495 24.7816ZM15.8705 27.8431L14.1482 24.7813L12.4051 25.7619L14.1273 28.8237L15.8705 27.8431Z"
        fill="#442873"
        mask="url(#path-1-outside-1_1299_7706)"
      />
    </svg>
  );
}
