import { SVGProps } from "react";

export default function EyeClosedIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 6.50018C14.76 6.50018 17 8.74018 17 11.5002C17 12.0102 16.9 12.5002 16.76 12.9602L19.82 16.0202C21.21 14.7902 22.31 13.2502 23 11.4902C21.27 7.11018 17 4.00018 12 4.00018C10.73 4.00018 9.51 4.20018 8.36 4.57018L10.53 6.74018C11 6.60018 11.49 6.50018 12 6.50018ZM2.71 3.16018C2.32 3.55018 2.32 4.18018 2.71 4.57018L4.68 6.54018C3.06 7.83018 1.77 9.53018 1 11.5002C2.73 15.8902 7 19.0002 12 19.0002C13.52 19.0002 14.97 18.7002 16.31 18.1802L19.03 20.9002C19.42 21.2902 20.05 21.2902 20.44 20.9002C20.83 20.5102 20.83 19.8802 20.44 19.4902L4.13 3.16018C3.74 2.77018 3.1 2.77018 2.71 3.16018ZM12 16.5002C9.24 16.5002 7 14.2602 7 11.5002C7 10.7302 7.18 10.0002 7.49 9.36018L9.06 10.9302C9.03 11.1102 9 11.3002 9 11.5002C9 13.1602 10.34 14.5002 12 14.5002C12.2 14.5002 12.38 14.4702 12.57 14.4302L14.14 16.0002C13.49 16.3202 12.77 16.5002 12 16.5002ZM14.97 11.1702C14.82 9.77018 13.72 8.68018 12.33 8.53018L14.97 11.1702Z" />
    </svg>
  );
}
