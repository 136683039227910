import { SVGProps } from "react";

export default function VerticalEllipsis(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_9282_20040)">
        <path
          d="M7.99984 5.33341C8.73317 5.33341 9.33317 4.73341 9.33317 4.00008C9.33317 3.26675 8.73317 2.66675 7.99984 2.66675C7.2665 2.66675 6.6665 3.26675 6.6665 4.00008C6.6665 4.73341 7.2665 5.33341 7.99984 5.33341ZM7.99984 6.66675C7.2665 6.66675 6.6665 7.26675 6.6665 8.00008C6.6665 8.73342 7.2665 9.33342 7.99984 9.33342C8.73317 9.33342 9.33317 8.73342 9.33317 8.00008C9.33317 7.26675 8.73317 6.66675 7.99984 6.66675ZM7.99984 10.6667C7.2665 10.6667 6.6665 11.2667 6.6665 12.0001C6.6665 12.7334 7.2665 13.3334 7.99984 13.3334C8.73317 13.3334 9.33317 12.7334 9.33317 12.0001C9.33317 11.2667 8.73317 10.6667 7.99984 10.6667Z"
          fill="#636567"
        />
      </g>
      <defs>
        <clipPath id="clip0_9282_20040">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
