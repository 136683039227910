import { SVGProps } from "react";

export default function ThumbsDownIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M9.99984 1.99976H3.99984C3.4465 1.99976 2.97317 2.33309 2.77317 2.81309L0.759837 7.51309C0.699837 7.66642 0.666504 7.82642 0.666504 7.99976V9.33309C0.666504 10.0664 1.2665 10.6664 1.99984 10.6664H6.2065L5.57317 13.7131L5.55317 13.9264C5.55317 14.1998 5.6665 14.4531 5.8465 14.6331L6.55317 15.3331L10.9398 10.9398C11.1865 10.6998 11.3332 10.3664 11.3332 9.99976V3.33309C11.3332 2.59976 10.7332 1.99976 9.99984 1.99976ZM9.99984 9.99976L7.1065 12.8931L7.84651 9.33309H1.99984V7.99976L3.99984 3.33309H9.99984V9.99976ZM12.6665 1.99976H15.3332V9.99976H12.6665V1.99976Z"
        fill="#87888C"
      />
    </svg>
  );
}
