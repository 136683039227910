import { SVGProps } from "react";

export default function ConfirmationGuyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={94} height={90} fill="none" {...props}>
      <path
        fill="#442873"
        d="M83.622 0H10.376c-4.269 0-7.66 1.584-9.305 4.346-1.557 2.612-1.412 5.901.406 9.26L39.48 83.795c1.903 3.512 4.573 5.447 7.52 5.447 2.944 0 5.614-1.935 7.52-5.448l38.002-70.188c1.82-3.358 1.964-6.646.408-9.259C91.284 1.585 87.892 0 83.622 0Zm5.56 11.798L51.18 81.985c-1.192 2.199-2.716 3.459-4.182 3.459-1.466 0-2.989-1.26-4.18-3.458L4.816 11.798C3.66 9.663 3.49 7.707 4.333 6.29c.944-1.583 3.146-2.492 6.043-2.492h73.246c2.898 0 5.101.909 6.044 2.493.845 1.416.672 3.372-.484 5.507Z"
      />
      <path
        fill="#442873"
        d="M54.12 51.732a3.57 3.57 0 0 0-1.988 6.533A5.931 5.931 0 0 1 47 61.235a5.941 5.941 0 0 1-5.934-5.934h-2.374c0 4.58 3.727 8.307 8.307 8.307 3.317 0 6.178-1.959 7.508-4.777 1.786-.196 3.18-1.692 3.18-3.53a3.569 3.569 0 0 0-3.567-3.569Z"
      />
      <path
        fill="#FFA300"
        d="M35.638 22.345a2.368 2.368 0 1 0 0-4.737 2.368 2.368 0 0 0 0 4.737Z"
      />
      <path
        fill="#FFA300"
        d="M27.638 33.831a13.27 13.27 0 0 0 10.361-4.989 13.27 13.27 0 0 0 10.361 4.99c7.313 0 13.264-5.95 13.264-13.264 0-7.313-5.95-13.263-13.264-13.263a13.27 13.27 0 0 0-10.361 4.99 13.27 13.27 0 0 0-10.361-4.99c-7.313 0-13.264 5.95-13.264 13.263 0 7.314 5.95 13.263 13.264 13.263Zm0-23.684a10.43 10.43 0 0 1 9.118 5.382l1.243 2.245 1.243-2.245a10.433 10.433 0 0 1 9.118-5.382c5.746 0 10.422 4.675 10.422 10.421 0 5.746-4.676 10.421-10.422 10.421a10.432 10.432 0 0 1-9.118-5.38l-1.243-2.245-1.243 2.244a10.432 10.432 0 0 1-9.118 5.381c-5.746 0-10.422-4.675-10.422-10.42 0-5.747 4.676-10.422 10.422-10.422Z"
      />
      <path fill="#FFA300" d="M56.36 22.345a2.369 2.369 0 1 0 0-4.737 2.369 2.369 0 0 0 0 4.737Z" />
    </svg>
  );
}
