import { SVGProps } from "react";

export default function SimilarityIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4944_113083)">
        <path d="M12.56 11.36L11.4 10.22C11.24 10.0733 11.0533 10 10.84 10C10.6267 10 10.44 10.08 10.28 10.24C10.1333 10.3867 10.06 10.5733 10.06 10.8C10.06 11.0267 10.1333 11.2133 10.28 11.36L12 13.08C12.1467 13.2267 12.3333 13.3 12.56 13.3C12.7867 13.3 12.9733 13.2267 13.12 13.08L16.52 9.68C16.68 9.52 16.76 9.32987 16.76 9.1096C16.76 8.88987 16.68 8.7 16.52 8.54C16.36 8.38 16.1701 8.3 15.9504 8.3C15.7301 8.3 15.54 8.38 15.38 8.54L12.56 11.36ZM8.8 16.8C8.36 16.8 7.98347 16.6435 7.6704 16.3304C7.3568 16.0168 7.2 15.64 7.2 15.2V5.6C7.2 5.16 7.3568 4.7832 7.6704 4.4696C7.98347 4.15653 8.36 4 8.8 4H18.4C18.84 4 19.2168 4.15653 19.5304 4.4696C19.8435 4.7832 20 5.16 20 5.6V15.2C20 15.64 19.8435 16.0168 19.5304 16.3304C19.2168 16.6435 18.84 16.8 18.4 16.8H8.8ZM5.6 20C5.16 20 4.78347 19.8435 4.4704 19.5304C4.1568 19.2168 4 18.84 4 18.4V8C4 7.77333 4.0768 7.5832 4.2304 7.4296C4.38347 7.27653 4.57333 7.2 4.8 7.2C5.02667 7.2 5.2168 7.27653 5.3704 7.4296C5.52347 7.5832 5.6 7.77333 5.6 8V18.4H16C16.2267 18.4 16.4165 18.4768 16.5696 18.6304C16.7232 18.7835 16.8 18.9733 16.8 19.2C16.8 19.4267 16.7232 19.6165 16.5696 19.7696C16.4165 19.9232 16.2267 20 16 20H5.6Z" />
      </g>
      <defs>
        <clipPath id="clip0_4944_113083">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
