import { SVGProps } from "react";

export default function TwitterIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0C4.02944 0 0 4.02944 0 9V15C0 19.9706 4.02944 24 9 24H15C19.9706 24 24 19.9706 24 15V9C24 4.02944 19.9706 0 15 0H9ZM16.387 9.01701C16.8689 8.95926 17.3273 8.8315 17.754 8.64242L17.753 8.64395C17.4336 9.12172 17.0316 9.53876 16.5659 9.87554C16.5705 9.97775 16.573 10.081 16.573 10.1832C16.573 13.3291 14.1788 16.9564 9.79929 16.9564C8.50553 16.9579 7.23875 16.5865 6.15052 15.8868C6.33913 15.909 6.52888 15.9201 6.71879 15.92C7.79088 15.9216 8.83234 15.5625 9.67562 14.9005C9.17886 14.8914 8.69737 14.7272 8.29858 14.4308C7.89978 14.1345 7.60364 13.7209 7.45161 13.2478C7.80848 13.3159 8.17614 13.3019 8.52682 13.207C7.98798 13.0981 7.5034 12.8061 7.15531 12.3806C6.80722 11.9551 6.61706 11.4223 6.61709 10.8726V10.8419C6.94767 11.0257 7.31735 11.1278 7.69537 11.1398C7.36942 10.9222 7.10223 10.6274 6.91752 10.2817C6.73281 9.93597 6.63629 9.55001 6.63651 9.15805C6.63651 8.72265 6.75405 8.31383 6.95846 7.96121C7.55629 8.69667 8.30208 9.29821 9.14743 9.72678C9.99279 10.1554 10.9188 10.4014 11.8654 10.4489C11.7456 9.93827 11.7977 9.40235 12.0137 8.92436C12.2297 8.44637 12.5974 8.05305 13.0598 7.80548C13.5222 7.5579 14.0534 7.46992 14.571 7.55519C15.0885 7.64045 15.5634 7.8942 15.922 8.27703C16.4552 8.17237 16.9664 7.97705 17.4336 7.69957C17.2563 8.25121 16.8843 8.71948 16.387 9.01701Z"
      />
    </svg>
  );
}
