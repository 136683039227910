import { SVGProps } from "react";

export default function Home(props: SVGProps<SVGSVGElement>) {
  return (
    <svg 
    {...props}
    width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.8293 8.44186L9.34329 0.7988C9.14816 0.623056 8.85184 0.623056 8.65671 0.7988L0.170695 8.44186C-0.178549 8.75642 0.0439613 9.33598 0.514 9.33598H2.56369V17.2356C2.56369 17.8837 3.05253 18.4092 3.65552 18.4092H5.83772C6.0691 18.4092 6.25666 18.2216 6.25666 17.9902V12.0594C6.25666 11.9333 6.35886 11.8311 6.48493 11.8311H11.5151C11.6411 11.8311 11.7433 11.9333 11.7433 12.0594V17.9902C11.7433 18.2216 11.9309 18.4092 12.1623 18.4092H14.3445C14.9475 18.4092 15.4363 17.8837 15.4363 17.2356V9.33598H17.486C17.956 9.33598 18.1785 8.75642 17.8293 8.44186Z"
        fill="#442873"
      />
    </svg>
  );
}
