import { SVGProps } from "react";

export default function SideBarToggleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M0.799512 7.99818C0.799512 8.44002 1.15767 8.79818 1.59951 8.79818L9.26543 8.79818L7.43567 10.628C7.12327 10.9404 7.12327 11.4469 7.43567 11.7593C7.74807 12.0718 8.25463 12.0718 8.56703 11.7593L11.7246 8.60178C11.893 8.45514 11.9995 8.23906 11.9995 7.99818C11.9995 7.7789 11.9113 7.58026 11.7685 7.43578C11.7547 7.4197 11.7402 7.40394 11.725 7.38874L8.56703 4.23074C8.25463 3.91834 7.74807 3.91834 7.43567 4.23074C7.12327 4.54322 7.12327 5.0497 7.43567 5.36218L9.27167 7.19818L1.59951 7.19818C1.15767 7.19818 0.799512 7.55634 0.799512 7.99818Z"
        fill="#442873"
      />
      <path
        d="M13.5997 15.2C13.5997 15.6418 13.9579 16 14.3997 16C14.8415 16 15.1997 15.6418 15.1997 15.2L15.1997 0.8C15.1997 0.35816 14.8415 0 14.3997 0C13.9579 0 13.5997 0.35816 13.5997 0.8L13.5997 15.2Z"
        fill="currentColor"
      />
    </svg>
  );
}
