import { SVGProps } from "react";

export default function RoundedArrowIcon(props: SVGProps<SVGSVGElement>) {
  return (    
    <svg {...props} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9968 21.2931C16.6772 21.2931 21.2825 16.6882 21.2825 11.0074C21.2825 5.32655 16.6772 0.72168 10.9968 0.72168C5.31633 0.72168 0.71106 5.32695 0.71106 11.0074C0.71106 16.6878 5.31593 21.2931 10.9968 21.2931ZM12.0969 16.0554L7.72905 11.6876C7.54823 11.5068 7.44664 11.2615 7.44664 11.0058C7.44664 10.7501 7.54823 10.5048 7.72905 10.324L12.0936 5.95936C12.2745 5.77853 12.5198 5.67696 12.7756 5.67699C13.0314 5.67703 13.2767 5.77867 13.4575 5.95956C13.6383 6.14045 13.7399 6.38576 13.7399 6.64153C13.7398 6.89731 13.6382 7.14259 13.4573 7.32342L9.77494 11.0074L13.4605 14.6934C13.6389 14.8747 13.7385 15.1192 13.7374 15.3736C13.7364 15.628 13.6349 15.8716 13.455 16.0515C13.2751 16.2314 13.0314 16.3329 12.7771 16.3339C12.5227 16.335 12.2782 16.2354 12.0969 16.057V16.0554Z" fill="#442873"/>
    </svg>
    
  );
}
