import { Outlet } from "react-router-dom";

export default function ReportSectionStateManagementService() {
  // const location = useLocation();
  // const navigate = useNavigate();
  // const dispatch = useAppDispatch();

  return (
    <>
      <Outlet />
    </>
  );
}
